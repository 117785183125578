import React, { createContext, useState } from "react";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminData, setAdminData] = useState(null);

  const login = (admin) => {
    setIsAdmin(true);
    setAdminData(admin);
  };

  const logout = () => {
    setIsAdmin(false);
    setAdminData(null);
  };

  return (
    <AdminContext.Provider
      value={{
        isAdmin,
        adminData,
        login,
        logout,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
