import React from "react";
import Slider from "../Slider/Slider";
import Category from "../Category/Category";
import BestSellers from "../BestSellers/BestSellers";

const Main = () => {
  return (
    <div>
      <Slider></Slider>
      <Category></Category>
      <BestSellers></BestSellers>
    </div>
  );
};

export default Main;
