import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faSearch,
  faTrashAlt,
  faHome,
  faInfoCircle,
  faBone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import {
  GET_PRODUCTS_API,
  POST_ORDERS_API,
  GET_LINKS_API,
} from "../../config/Endpoints";

const Navbar = () => {
  const {
    cartItems,
    updateQuantity,
    calculateSubtotal,
    removeFromCart,
    totalItems,
    addToCart,
  } = useContext(CartContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [showCart, setShowCart] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [customerName, setCustomerName] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [orderSent, setOrderSent] = useState(false);
  const [showAddedMessage, setShowAddedMessage] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    customerName: "",
    customerID: "",
    paymentMethod: "",
    shippingAddress: "",
  });

  const links = {
    home: "Inicio",
    contact: "Contáctanos",
    about: "Conoce Makiy Pets",
    petFoods: "Alimentos para mascotas",
  };

  const [contactLink, setContactLink] = useState("");

  useEffect(() => {
    const fetchContactLink = async () => {
      try {
        const response = await axios.get(GET_LINKS_API);
        const contactUrl = response.data?.result?.[0]?.content; // Utiliza encadenamiento opcional para acceder a 'content'
        if (contactUrl) {
          setContactLink(contactUrl);
        } else {
          console.error("Unexpected API response structure", response.data);
        }
      } catch (error) {
        console.error("Error fetching contact link:", error);
      }
    };

    fetchContactLink();
  }, []);

  const handleSearch = async (term) => {
    if (term.trim() === "") {
      setSearchResults([]);
      return;
    }
    setSearchLoading(true);
    setSearchError(null);
    try {
      const response = await axios.get(GET_PRODUCTS_API);
      const allProducts = response.data.result;
      if (!Array.isArray(allProducts)) {
        throw new Error("Expected an array of products");
      }
      const filteredProducts = allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
      setSearchResults(filteredProducts);
    } catch (error) {
      setSearchError(`Error fetching products: ${error.message}`);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    handleSearch(term);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch(searchTerm);
    }
  };

  const handlePreview = (product) => {
    setSelectedProduct(product);
    setQuantity(1);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  const handleAddToCart = (product) => {
    addToCart(product, quantity);
    setShowAddedMessage(true);
    setTimeout(() => {
      setShowAddedMessage(false);
    }, 2000);
  };

  const openCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const closeCustomerModal = () => {
    setShowCustomerModal(false);
    setConfirmationMessage("");
    setOrderSent(false);
    setCustomerName("");
    setCustomerID("");
    setPaymentMethod("");
    setShippingAddress("");
    setFieldErrors({
      customerName: "",
      customerID: "",
      paymentMethod: "",
      shippingAddress: "",
    });
  };

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setFieldErrors({ ...fieldErrors, [name]: "" });
    if (name === "customerName") setCustomerName(value);
    if (name === "customerID") {
      const onlyDigits = value.replace(/\D/g, "").slice(0, 10);
      setCustomerID(onlyDigits);
    }
    if (name === "paymentMethod") setPaymentMethod(value);
    if (name === "shippingAddress") setShippingAddress(value);
  };

  const validateCustomerDetails = () => {
    let valid = true;
    let errors = {
      customerName: "",
      customerID: "",
      paymentMethod: "",
      shippingAddress: "",
    };

    if (!customerName) {
      errors.customerName = "Nombre completo es obligatorio";
      valid = false;
    }
    if (!customerID) {
      errors.customerID = "Número de celular es obligatorio";
      valid = false;
    } else if (!/^\d+$/.test(customerID)) {
      errors.customerID =
        "El número de celular debe contener solo dígitos numéricos";
      valid = false;
    }
    if (!paymentMethod) {
      errors.paymentMethod = "Método de pago es obligatorio";
      valid = false;
    }
    if (!shippingAddress) {
      errors.shippingAddress = "Dirección de envío es obligatoria";
      valid = false;
    }

    setFieldErrors(errors);
    return valid;
  };

  const getLocalDateTime = () => {
    const now = new Date();
    return new Intl.DateTimeFormat("es-EC", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "America/Guayaquil",
    }).format(now);
  };

  const handleFinalizeForm = async () => {
    if (!validateCustomerDetails()) return;

    const orderDetails = {
      user_id: customerID,
      user_name: customerName,
      order_date: getLocalDateTime(),
      status: "pendiente",
      total: calculateSubtotal(),
      shipping_address: shippingAddress,
      payment_method: paymentMethod,
      quantity: cartItems.reduce((total, item) => total + item.quantity, 0),
      products: cartItems.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
    };

    try {
      const response = await axios.post(POST_ORDERS_API, orderDetails);
      console.log("Response:", response);

      setConfirmationMessage(
        "Gracias por preferirnos, nos contactaremos con usted lo antes posible."
      );
      setShowConfirmationModal(true);
      setOrderSent(true);
    } catch (error) {
      if (error.response) {
        // El servidor respondió con un código de estado fuera del rango de 2xx
        console.error("Error finalizando la compra:", error.response.data);
      } else if (error.request) {
        // La solicitud se hizo pero no se recibió respuesta
        console.error("No se recibió respuesta del servidor:", error.request);
      } else {
        // Algo pasó al configurar la solicitud que desencadenó un error
        console.error("Error", error.message);
      }
    }
  };

  const handleWhatsAppOrder = () => {
    const message = `Orden realizada por *${customerName}* con número de celular *${customerID}*.\n
    *Detalle de la nueva orden:*\n
    *Fecha:* ${getLocalDateTime()}\n
    *Dirección de envío:* ${shippingAddress}\n
    *Método de pago:* ${paymentMethod}\n
    *Productos:*\n
    ${cartItems
      .map(
        (item) =>
          `${item.name} - Cantidad: ${item.quantity} - Precio: $${item.price}`
      )
      .join("\n")}\n
    *Total:* $${calculateSubtotal()}`;

    const whatsappUrl = `https://api.whatsapp.com/send?phone=+593996699120&text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp URL in a new tab/window
    window.open(whatsappUrl, "_blank");
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  return (
    <>
      <div className="bg-custom-purple p-2">
        <h3 className="text-white font-serif text-5xl font-bold tracking-normal leading-none text-center">
          Makiy Pets
        </h3>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center p-4 bg-white relative">
        <img className="h-12" src={logo} alt="store" />
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-12 items-center mt-4 md:mt-0">
          <Link
            to="/home"
            className="flex items-center justify-center text-blue-800 hover:text-blue-600 font-semibold font-serif"
          >
            <FontAwesomeIcon icon={faHome} className="mr-1" />
            {links.home}
          </Link>
          <Link
            to="/about"
            className="flex items-center justify-center text-blue-800 hover:text-blue-600 font-semibold font-serif"
          >
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            {links.about}
          </Link>
          <Link
            to="/pet-foods"
            className="flex items-center justify-center text-blue-800 hover:text-blue-600 font-semibold font-serif"
          >
            <FontAwesomeIcon icon={faBone} className="mr-1" />
            {links.petFoods}
          </Link>
          <a
            href={contactLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center text-green-600 hover:text-green-500 font-semibold"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="w-5 h-5 mr-1" />
            {links.contact}
          </a>
        </div>
        <div className="flex items-center border border-gray-300 rounded-full shadow-md p-2 w-full md:w-2/5 relative mt-4 md:mt-0">
          <input
            type="text"
            placeholder="Buscar en Makiy Pets"
            className="p-1 rounded-l-full outline-none flex-grow font-serif"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
          />
          <button
            onClick={() => handleSearch(searchTerm)}
            className="flex items-center justify-center p-2"
          >
            <FontAwesomeIcon
              icon={faSearch}
              className="w-5 h-5 text-gray-600"
            />
          </button>
          {searchTerm && (
            <div className="absolute top-full mt-2 w-full bg-white shadow-md rounded-lg z-50">
              <ul className="max-h-64 overflow-y-auto">
                {searchLoading ? (
                  <li className="p-2 text-center">Cargando...</li>
                ) : searchError ? (
                  <li className="p-2 text-center text-red-500">
                    {searchError}
                  </li>
                ) : searchResults.length > 0 ? (
                  searchResults.map((product) => (
                    <li
                      key={product.id}
                      className="flex items-center p-4 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handlePreview(product)}
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-16 h-16 rounded-lg"
                      />
                      <div className="ml-4">
                        <h4 className="font-semibold font-serif">
                          {product.name}
                        </h4>
                        <p className="text-gray-600 text-sm font-serif">
                          Precio (inc. IVA): ${product.price.toFixed(2)}
                        </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-center">
                    No se encontraron productos
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        <Link
          to="/login"
          className="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full hover:bg-gray-400 mt-4 md:mt-0"
        >
          <FontAwesomeIcon icon={faUser} className="w-6 h-6 text-black" />
        </Link>
        <div className="flex items-center ml-2 mt-4 md:mt-0">
          <button
            className="flex items-center justify-center w-10 h-10 bg-orange-300 rounded-full hover:bg-yellow-500 relative"
            onClick={() => setShowCart(!showCart)}
          >
            <FontAwesomeIcon
              icon={faShoppingCart}
              className="w-6 h-6 text-black"
            />
            {totalItems > 0 && (
              <span className="absolute transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full px-2 py-1 text-xs right-2 top-1">
                {totalItems}
              </span>
            )}
          </button>
        </div>
      </div>
      {showCart && (
        <div className="absolute right-0 mt-2 w-full md:w-96 bg-white shadow-lg rounded-lg p-6 z-50 border border-gray-300">
          <h3 className="font-bold font-serif text-lg text-center mb-4">
            Carrito de Compras
          </h3>
          {cartItems.length === 0 ? (
            <p className="text-center text-red-500 font-serif">
              El carrito está vacío
            </p>
          ) : (
            <>
              <ul>
                {cartItems.map((item) => (
                  <li
                    key={item.id}
                    className="flex flex-col md:flex-row justify-between items-center mt-2 bg-gray-100 p-3 rounded-lg"
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-16 h-16 rounded-lg"
                    />
                    <div className="ml-2 flex-grow text-center md:text-left">
                      <h4 className="font-semibold font-serif">{item.name}</h4>
                      <p className="text-gray-600 font-serif">
                        ${item.price.toFixed(2)} (inc. IVA)
                      </p>
                      <div className="flex flex-col md:flex-row items-center mt-1">
                        <label
                          htmlFor={`quantity-${item.id}`}
                          className="mr-2 font-serif"
                        >
                          Cantidad:
                        </label>
                        <input
                          type="number"
                          id={`quantity-${item.id}`}
                          className="w-16 p-1 border rounded"
                          value={item.quantity}
                          min="1"
                          step="1"
                          onChange={(e) => {
                            const value = Math.max(
                              1,
                              parseInt(e.target.value, 10)
                            );
                            if (!isNaN(value)) {
                              updateQuantity(item.id, value);
                            }
                          }}
                        />
                        <button
                          className="ml-2 text-red-500 hover:text-red-700"
                          onClick={() => removeFromCart(item.id)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-4 font-serif text-center md:text-left">
                <h4 className="font-bold text-lg">
                  Subtotal: ${calculateSubtotal()}
                </h4>
                <button
                  className="font-bold bg-green-700 text-white w-full py-3 mt-2 hover:bg-green-600 rounded-full"
                  onClick={openCustomerModal}
                >
                  Continuar
                </button>
              </div>
            </>
          )}
        </div>
      )}
      <div className="bg-custom-orange p-2 w-full flex flex-col md:flex-row justify-around">
        <div className="text-red-600 font-serif text-1xl font-bold tracking-normal leading-none text-center">
          Descuentos increíbles
        </div>
        <div className="text-red-600 font-serif text-1xl font-bold tracking-normal leading-none text-center">
          Diferentes métodos de pago
        </div>
        <div className="text-red-600 font-serif text-1xl font-bold tracking-normal leading-none text-center">
          Contáctanos en cualquier momento
        </div>
      </div>
      {selectedProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={selectedProduct.image}
              alt={selectedProduct.name}
              className="w-full h-auto rounded-lg"
            />
            <h3 className="font-bold font-serif text-2xl mt-4 ">
              {selectedProduct.name}
            </h3>
            <p className="font-serif mt-2">{selectedProduct.description}</p>
            <p className="font-serif text-xl mt-4 text-green-500">
              Desde ${selectedProduct.price.toFixed(2)} (inc. IVA)
            </p>
            <button
              className="mt-4 bg-orange-400 text-white font-serif py-2 px-4 rounded hover:bg-blue-400 w-full"
              onClick={() => handleAddToCart(selectedProduct)}
            >
              Añadir al carrito
            </button>
            {showAddedMessage && (
              <div className="absolute left-1/2 transform -translate-x-1/2 bg-blue-900 text-white py-2 px-4 rounded-lg text-lg font-bold opacity-90 mt-4">
                <p className="font-serif">Producto añadido</p>
              </div>
            )}
          </div>
        </div>
      )}
      {showCustomerModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative overflow-y-auto max-h-full">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={closeCustomerModal}
            >
              &times;
            </button>
            <h3 className="font-bold font-serif text-center">
              Por favor incluya los siguientes datos y confirme su entrega
            </h3>
            <div className="mb-4 text-left">
              <br />
              <label className="block font-serif" htmlFor="customerName">
                Ingrese sus nombres y apellidos
              </label>
              <input
                type="text"
                id="customerName"
                name="customerName"
                placeholder="Nombre Completo"
                className="w-full p-2 border rounded"
                value={customerName}
                onChange={handleCustomerChange}
                disabled={orderSent}
              />
              {fieldErrors.customerName && (
                <p className="text-red-500">{fieldErrors.customerName}</p>
              )}
            </div>
            <div className="mb-4 text-left">
              <br />
              <label className="block font-serif" htmlFor="customerID">
                Ingrese el número de celular para gestionar su entrega
              </label>
              <input
                type="text"
                id="customerID"
                name="customerID"
                placeholder="Número de celular"
                className="w-full p-2 border rounded"
                value={customerID}
                onChange={handleCustomerChange}
                disabled={orderSent}
              />
              {fieldErrors.customerID && (
                <p className="text-red-500">{fieldErrors.customerID}</p>
              )}
            </div>
            <div className="mb-4 text-left">
              <br />
              <label className="block font-serif" htmlFor="paymentMethod">
                Seleccione su método de pago
              </label>
              <select
                id="paymentMethod"
                name="paymentMethod"
                className="w-full p-2 border rounded"
                value={paymentMethod}
                onChange={handleCustomerChange}
                disabled={orderSent}
              >
                <option value="">Seleccione un método de pago</option>
                <option value="Efectivo">Efectivo</option>
                <option value="Transferencia">Transferencia</option>
                <option value="Tarjeta de débito">Tarjeta de débito</option>
                <option value="Tarjeta de crédito">Tarjeta de crédito</option>
              </select>
              {fieldErrors.paymentMethod && (
                <p className="text-red-500">{fieldErrors.paymentMethod}</p>
              )}
            </div>
            <div className="mb-4 text-left">
              <br />
              <label className="block font-serif" htmlFor="shippingAddress">
                Dirección exacta de su domicilio para la entrega del producto
              </label>
              <input
                type="text"
                id="shippingAddress"
                name="shippingAddress"
                placeholder="Dirección Exacta"
                className="w-full p-2 border rounded"
                value={shippingAddress}
                onChange={handleCustomerChange}
                disabled={orderSent}
              />
              {fieldErrors.shippingAddress && (
                <p className="text-red-500">{fieldErrors.shippingAddress}</p>
              )}
            </div>
            {!orderSent && (
              <button
                className="font-bold , font-serif  bg-green-700 text-white w-full py-3 rounded mt-2 hover:bg-green-600"
                onClick={handleFinalizeForm}
              >
                Confirmar Entrega
              </button>
            )}
          </div>
        </div>
      )}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={closeConfirmationModal}
            >
              &times;
            </button>
            <h3 className="font-bold font-serif">Orden Recibida</h3>
            <p className="text-green-600 font-serif ">{confirmationMessage}</p>
            {orderSent && (
              <button
                className="font-bold , font-serif bg-green-800 text-white w-full py-3 rounded-full mt-2  hover:bg-green-900"
                onClick={handleWhatsAppOrder}
              >
                Enviar orden por WhatsApp
              </button>
            )}
            {orderSent && (
              <button
                className="font-bold , font-serif bg-custom-purple  text-white w-full py-3 rounded-full mt-2  hover:bg-blue-900"
                onClick={() => {
                  closeConfirmationModal();
                  closeCustomerModal();
                }}
              >
                Aceptar
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
