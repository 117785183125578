import React from "react";
import { useNavigate } from "react-router-dom"; // Importar useNavigate de react-router-dom
import dogImage from "../../assets/images/dog.png";
import catImage from "../../assets/images/cat.png";
import "./Category.css";

const Category = () => {
  const navigate = useNavigate(); // Inicializar el hook useNavigate

  return (
    <div className="category-section">
      <div className="category-header font-serif">
        <h2 className="font-bold">Somos una tienda de mascotas completa</h2>
        <p className="subtitle font-serif font-bold">
          En Makiy Pets te ofrecemos una gran variedad de productos y alimentos
          para tu peludito.
        </p>
      </div>
      <div className="category-content">
        <div className="category-item">
          <br />
          <img src={dogImage} alt="Perros" className="category-image" />
          <button
            className="category-button font-bold"
            onClick={() => navigate("/petfoods/perros")} // Redirigir a la categoría de perros
          >
            +
          </button>
        </div>
        <div className="category-item">
          <br />
          <img src={catImage} alt="Gatos" className="category-image" />
          <button
            className="category-button font-bold"
            onClick={() => navigate("/petfoods/gatos")} // Redirigir a la categoría de gatos
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default Category;
