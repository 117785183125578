import React from "react";
import "./AboutUs.css";
import about from "../../assets/images/about.png";

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="about-us-content">
        <div className="about-us-image">
          <img src={about} alt="Quiénes somos" />
        </div>
        <div className="about-us-text font-serif">
          <h1 className="font-bold">Quiénes Somos</h1>
          <p className="subtitle">
            En Makiy Pets, somos una comunidad dedicada a la felicidad y el
            bienestar de tus fieles compañeros. Nuestro equipo, compuesto por
            apasionados amantes de los animales, se esfuerza diariamente para
            ofrecerte productos de la más alta calidad y un servicio
            personalizado que supera tus expectativas.
          </p>
          <p>
            <br />
            Nos enorgullece haber ganado la confianza de dueños de mascotas en
            toda la región, y trabajamos con dedicación para seguir siendo tu
            opción preferida. En Makiy Pets, tu mascota es nuestra prioridad, y
            estamos aquí para acompañarte en cada paso del camino, asegurándonos
            de que recibas lo mejor para quien más quieres.
          </p>
        </div>
      </div>
      <div className="about-us-vision-mission font-serif">
        <div className="vision-mission-container">
          <hr className="divider" />
          <div className="vision-mission-section">
            <h2 className="font-bold">Visión</h2>
            <p>
              Ser el referente líder en el mercado de alimentos para mascotas,
              reconocido por nuestra dedicación al bienestar animal y por
              ofrecer productos innovadores y de la más alta calidad, creando un
              impacto positivo en la vida de cada mascota y su familia.
            </p>
          </div>
          <hr className="divider" />
          <div className="vision-mission-section">
            <h2 className="font-bold">Misión</h2>
            <p>
              Proveer a nuestros clientes con una amplia variedad de alimentos
              nutritivos y saludables para mascotas, acompañados de un servicio
              excepcional y asesoramiento experto, promoviendo una vida plena y
              feliz para todas las mascotas que forman parte de nuestra
              comunidad.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
