import React from "react";

const Policies = () => {
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="max-w-5xl mx-auto bg-white p-8 shadow-md rounded-lg">
        <h1 className="text-3xl font-bold font-serif text-center mb-6 text-indigo-700">
          TÉRMINOS Y CONDICIONES DE COMPRA
        </h1>
        <ul className="list-disc ml-5 mt-4 text-justify space-y-4">
          <li>
            Los precios de nuestros productos estarán sujetos a cambios, sin
            previo aviso.
          </li>
          <li>
            Nos reservamos el derecho, sin embargo, no estamos obligados, a
            limitar las ventas de nuestros productos y/o servicios a cualquier
            persona, región geográfica y/o jurisdicción. Por lo que podemos
            ejercer este derecho, en cada caso.
          </li>
          <li>
            Nos reservamos el derecho de limitar las cantidades de cualquier
            producto y/o servicio que ofrecemos.
          </li>
          <li>
            Todas las descripciones y características de los productos y/o
            precios de los mismos, están sujetos a cambios en cualquier momento,
            sin previo aviso, a discreción exclusiva de Makiy Pets.
          </li>
          <li>
            Nos reservamos el derecho de suspender cualquier producto en
            cualquier momento, a discreción exclusiva de Makiy Pets.
          </li>
          <li>
            Nos reservamos el derecho de actualizar, modificar y/o reemplazar,
            en cualquier momento, estas políticas de compra.
          </li>
          <li>
            Makiy Pets describirá los productos en el sitio web, garantizando la
            calidad de los mismos, por lo que los compradores deberán revisar
            las descripciones, ya que no podrán realizar reclamos posteriores.
          </li>
          <li>
            El comprador se compromete a proporcionar información real,
            actualizada, completa y precisa, respecto de sus datos, así como la
            información de la compra a efectuarse, y de las realizadas en
            nuestra tienda.
          </li>
          <li>
            El comprador en el momento que efectúa la compra, expresamente
            acepta todos los términos y condiciones establecidos por Makiy Pets,
            dejando expresamente establecido que no efectuará reclamos
            posteriores, ya sea de la compra en sí o de los materiales de las
            prendas, entre otros. Siendo responsabilidad exclusiva de los
            compradores revisar detalladamente cada producto, como el precio y
            tiempos de entrega.
          </li>
        </ul>
        <h2 className="text-2xl font-bold mt-8 text-indigo-600 text-center">
          Envíos y entregas de pedidos
        </h2>
        <p className="mt-4 text-justify">
          Todos los envíos a efectuarse, se entregan entre 24 a 48 horas
          laborables, dependiendo de la ciudad o provincia de entrega.
        </p>
        <h2 className="text-2xl font-bold mt-8 text-indigo-600 text-center">
          Privacidad y seguridad
        </h2>
        <p className="mt-4 text-justify">
          Toda la información proporcionada por los compradores, será de uso
          exclusivo de Makiy Pets para poder perfeccionar la compra de
          productos, por lo que, se compromete en todo momento de mantenerla en
          reserva y de no ser divulgada a terceros.
        </p>
        <h2 className="text-2xl font-bold mt-8 text-indigo-600 text-center">
          Devoluciones y reemplazos
        </h2>
        <p className="mt-4 text-justify">
          Makiy Pets no efectuará devoluciones de dinero, bajo ningún concepto.
          Únicamente se efectuarán cambios, si por parte de Makiy Pets se ha
          cometido un error acerca del producto enviado, por lo que se procederá
          con su corrección inmediata, asumiendo los gastos de envío. Es
          obligación del comprador verificar que el pedido realizado sea el
          correcto, antes de proceder con el pago del mismo, ya que una vez que
          se ha recibido el correo de confirmación de que el pedido ha sido
          enviado, no se podrá hacer cambios ni devoluciones.
        </p>
        <p className="mt-8 text-center">
          Cualquier inquietud sobre los Términos y Condiciones de Servicio deben
          ser enviadas a{" "}
          <a
            href="mailto:ventas@makiypets.ec"
            className="text-indigo-600 underline"
          >
            ventas@makiypets.ec
          </a>
        </p>
      </div>
    </div>
  );
};

export default Policies;
