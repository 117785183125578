import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./BestSellers.css";
import { CartContext } from "../../Context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { GET_PRODUCTS_API } from "../../config/Endpoints";

const BestSellers = () => {
  const { addToCart } = useContext(CartContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMessageMain, setShowMessageMain] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(GET_PRODUCTS_API);
        const allProducts = response.data.result;

        if (!Array.isArray(allProducts)) {
          throw new Error("Expected an array of products");
        }

        const availableProducts = allProducts.filter(
          (product) => product.available
        );

        const selectedProducts = [];
        const maxProducts = Math.min(12, availableProducts.length);
        while (selectedProducts.length < maxProducts) {
          const randomIndex = Math.floor(
            Math.random() * availableProducts.length
          );
          const selectedProduct = availableProducts[randomIndex];
          if (!selectedProducts.includes(selectedProduct)) {
            selectedProducts.push(selectedProduct);
          }
        }

        setProducts(selectedProducts);
      } catch (error) {
        setError(`Error fetching products: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleAddToCart = (product, isFromModal) => {
    addToCart(product, quantity);
    if (isFromModal) {
      setShowMessageModal(true);
      setTimeout(() => setShowMessageModal(false), 2000);
    } else {
      setShowMessageMain(true);
      setTimeout(() => setShowMessageMain(false), 2000);
    }
  };

  const handlePreview = (product) => {
    setSelectedProduct(product);
    setQuantity(1);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="best-sellers font-serif">
      <br />
      {showMessageMain && (
        <div className="add-to-cart-message">Producto añadido</div>
      )}
      <br />
      <h2 className="title font-bold bg-custom-orange">
        LOS PRODUCTOS MÁS VENDIDOS
      </h2>
      <div className="products-grid">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <h3 className="product-name font-bold">{product.name}</h3>
            <p className="product-description">{product.description}</p>
            <p className="product-price">
              ${product.price.toFixed(2)} (inc. IVA)
            </p>
            <div className="product-buttons">
              <button
                className="preview-button"
                onClick={() => handlePreview(product)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <button
                className="add-to-cart-button"
                onClick={() => handleAddToCart(product, false)}
              >
                <FontAwesomeIcon icon={faCartPlus} />{' '}
                Añadir al carrito
              </button>
            </div>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative">
            <button
              className="absolute top-2 right-2 text-gray-700 text-xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={selectedProduct.image}
              alt={selectedProduct.name}
              className="w-full h-auto rounded-lg"
            />
            <h3 className="font-bold font-serif text-2xl mt-4 ">
              {selectedProduct.name}
            </h3>
            <p className="font-serif mt-2">{selectedProduct.description}</p>
            <p className="font-serif text-xl mt-4 text-green-500">
              ${selectedProduct.price.toFixed(2)} (inc. IVA)
            </p>
            <button
              className="mt-4 bg-custom-orange text-white font-serif py-2 px-4 rounded hover:bg-custom-purple w-full"
              onClick={() => handleAddToCart(selectedProduct, true)}
            >
              Añadir al carrito
            </button>
            {showMessageModal && (
              <div className="absolute left-1/2 transform -translate-x-1/2 bg-custom-purple text-white py-2 px-4 rounded-lg text-lg font-bold opacity-90 mt-4">
                <p className="font-serif">Producto añadido</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BestSellers;
