import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_LINKS_API, PUT_LINKS_API } from "../../config/Endpoints";

const EditWhatsApp = () => {
  const [linkData, setLinkData] = useState({
    id: "",
    name: "",
    description: "",
    content: "",
    available: true,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLinkData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(GET_LINKS_API);
        const data = response.data.result;

        if (data && data.length > 0) {
          const link = data[0];
          setLinkData({
            id: link.id,
            name: link.name,
            description: link.description,
            content: link.content,
            available: link.available,
          });
        } else {
          throw new Error("No se encontraron datos de enlace");
        }
      } catch (error) {
        setError(`Error fetching link data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchLinkData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLinkData({
      ...linkData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(PUT_LINKS_API, linkData);
      toast.success("Enlace de WhatsApp actualizado correctamente");
    } catch (error) {
      toast.error(`Error al actualizar el enlace: ${error.message}`);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="edit-navbar font-serif p-4">
      <ToastContainer />
      <h2 className="text-2xl font-bold text-center mb-4">
        Editar Enlace de WhatsApp
      </h2>
      <div className="space-y-4">
        <div>
          <label className="block text-gray-700 font-serif">Nombre</label>
          <input
            type="text"
            name="name"
            value={linkData.name}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-serif">Descripción</label>
          <input
            type="text"
            name="description"
            value={linkData.description}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-serif">
            Enlace de WhatsApp
          </label>
          <input
            type="text"
            name="content"
            value={linkData.content}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="flex items-center">
          <label className="block text-gray-700 font-serif mr-2">
            Disponible
          </label>
          <input
            type="checkbox"
            name="available"
            checked={linkData.available}
            onChange={handleInputChange}
            className="h-5 w-5 text-blue-600 focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <button
          onClick={handleSaveEdit}
          className="font-serif w-full p-3 bg-custom-purple text-white rounded-lg hover:bg-blue-800 transition-all duration-300"
        >
          Guardar Cambios
        </button>
      </div>
    </div>
  );
};

export default EditWhatsApp;
