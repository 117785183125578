import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./PetFoods.css";
import { CartContext } from "../../Context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { GET_PRODUCTS_API } from "../../config/Endpoints";

const PetFoods = () => {
  const { addToCart } = useContext(CartContext);
  const { category } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMessageMain, setShowMessageMain] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(GET_PRODUCTS_API);
        const allProducts = response.data.result;

        if (!Array.isArray(allProducts)) {
          throw new Error("Expected an array of products");
        }

        setProducts(allProducts);
        if (category) {
          setFilter(category);
        }
      } catch (error) {
        setError(`Error fetching products: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category]);

  const handleAddToCart = (product, isFromModal) => {
    addToCart(product, quantity);
    if (isFromModal) {
      setShowMessageModal(true);
      setTimeout(() => setShowMessageModal(false), 2000);
    } else {
      setShowMessageMain(true);
      setTimeout(() => setShowMessageMain(false), 2000);
    }
  };

  const handlePreview = (product) => {
    setSelectedProduct(product);
    setQuantity(1);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  const filteredProducts = filter
    ? products.filter((product) => product.category_id === filter)
    : products;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="pet-foods font-serif">
      <br />
      {showMessageMain && (
        <div className="add-to-cart-message">Producto añadido</div>
      )}
      <br />
      <h2 className="title font-bold">ALIMENTOS PARA MASCOTAS</h2>
      <div className="filter-container font-bold ">
        <button onClick={() => setFilter("perros")} className="filter-button  bg-custom-orange ">
          Perros
        </button>
        <button onClick={() => setFilter("gatos")} className="filter-button  bg-custom-orange">
          Gatos
        </button>
        <button onClick={() => setFilter("")} className="filter-button  bg-custom-orange">
          Todos
        </button>
      </div>
      <div className="products-grid">
        {filteredProducts.map((product) => (
          <div key={product.id} className="product-card">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <h3 className="product-name font-bold">{product.name}</h3>
            <p className="product-description">{product.description}</p>
            <p className="product-price">
              ${product.price.toFixed(2)} (inc. IVA)
            </p>
            {product.available ? (
              <div className="product-buttons">
                <button
                  className="preview-button"
                  onClick={() => handlePreview(product)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
                <button
                  className="add-to-cart-button"
                  onClick={() => handleAddToCart(product, false)}
                >
                  <FontAwesomeIcon icon={faCartPlus} /> {' '}
                    Añadir al carrito
                </button>
              </div>
            ) : (
              <p className="product-status">Agotado</p>
            )}
          </div>
        ))}
      </div>

      <div>
        {selectedProduct && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full mx-2 md:mx-0 relative">
              <button
                className="absolute top-2 right-2 text-gray-700 text-xl"
                onClick={closeModal}
              >
                &times;
              </button>
              <img
                src={selectedProduct.image}
                alt={selectedProduct.name}
                className="w-full h-auto rounded-lg"
              />
              <h3 className="font-bold font-serif text-2xl mt-4 ">
                {selectedProduct.name}
              </h3>
              <p className="font-serif mt-2">{selectedProduct.description}</p>
              <p className="font-serif text-xl mt-4 text-green-500">
                ${selectedProduct.price.toFixed(2)} (inc. IVA)
              </p>
              <button
                className="mt-4 bg-custom-orange text-white font-serif py-2 px-4 rounded hover:bg-custom-purple w-full"
                onClick={() => handleAddToCart(selectedProduct, true)}
              >
                Añadir al carrito
              </button>
              {showMessageModal && (
                <div className="absolute left-1/2 transform -translate-x-1/2 bg-custom-purple  text-white py-2 px-4 rounded-lg text-lg font-bold opacity-90 mt-4">
                  <p className="font-serif">Producto añadido</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PetFoods;
