import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../../assets/images/logo.png";
import pay from "../../assets/images/pay.png";
import axios from "axios";
import { useState, useEffect } from "react";
import { GET_LINKS_API } from "../../config/Endpoints";

const Footer = () => {
  const [contactLink, setContactLink] = useState("");

  useEffect(() => {
    const fetchContactLink = async () => {
      try {
        const response = await axios.get(GET_LINKS_API);
        const contactUrl = response.data?.result?.[0]?.content; // Utiliza encadenamiento opcional para acceder a 'content'
        if (contactUrl) {
          setContactLink(contactUrl);
        } else {
          console.error("Unexpected API response structure", response.data);
        }
      } catch (error) {
        console.error("Error fetching contact link:", error);
      }
    };

    fetchContactLink();
  }, []);

  return (
    <footer className="footer">
      <br />
      <br />
      <div className="footer-divider"></div>
      <div className="footer-content">
        <div className="footer-section logo">
          <img className="logo-image" src={logo} alt="store" />
        </div>
        <div className="footer-section links font-serif">
          <br />
          <h4 className="font-bold">MAKIY PETS</h4>
          <ul>
            <li>
              <Link to="/home">Inicio</Link>
            </li>
            <li>
              <Link to="/about">Conoce Makiy Pets</Link>
            </li>
            <li>
              <Link to="/pet-foods">Alimentos para mascotas</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section links font-serif">
          <br />
          <h4 className="font-bold">CATEGORÍAS</h4>
          <ul>
            <li>
              <Link to="/petfoods/perros">Perros</Link>
            </li>
            <li>
              <Link to="/petfoods/gatos">Gatos</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section links font-serif">
          <br />
          <h4 className="font-bold">NOSOTROS</h4>
          <ul>
            <li>
              <Link to="/policies">Políticas</Link>
            </li>
            <li>
              <Link to="/about">Quienes somos</Link>
            </li>
            <a href={contactLink} target="_blank" rel="noopener noreferrer">
              Contacto
            </a>
          </ul>
        </div>
        <div className="footer-section contact font-serif">
          <br />
          <h4 className="font-bold">CONTACTOS</h4>
          <p>Telfs.: (593) 996699120</p>
          <p>Email: ventas@makipets.ec</p>
        </div>
        <div className="footer-section logo">
          <img className="pay-image mt-2" src={pay} alt="payment" />
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="footer-bottom font-serif">
        <p>&copy; 2024 Todos los derechos reservados.</p>
        <br />
      </div>
    </footer>
  );
};

export default Footer;
