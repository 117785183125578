import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_ORDERS_API, PUT_ORDER_API } from "../../config/Endpoints";

const EditOrder = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const response = await axios.get(GET_ORDERS_API);
        setOrders(response.data.result);
      } catch (error) {
        setError(`Error fetching orders: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  const handleStatusChange = (e, order) => {
    const updatedOrder = { ...order, status: e.target.value };
    setOrders(orders.map((o) => (o.id === order.id ? updatedOrder : o)));
  };

  const handleSaveOrder = async (order) => {
    try {
      //await axios.put(`${PUT_ORDER_TEMP_API}/${order.id}`, order);
      await axios.put(PUT_ORDER_API, order);
      toast.success("Orden actualizada correctamente");
    } catch (error) {
      toast.error(`Error al actualizar la orden: ${error.message}`);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="edit-orders font-serif p-4">
      <ToastContainer />
      <h2 className="text-2xl font-bold text-center mb-4">
        Administrar Órdenes
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {orders.map((order) => (
          <div
            key={order.id}
            className="p-4 border rounded-lg shadow-lg text-left "
          >
            <h3 className="font-bold">Cliente: {order.user_name}</h3>{" "}
            <p>Número de celular: {order.user_id}</p>
            <p>Fecha de creación: {order.order_date}</p>
            <p>Método de pago: {order.payment_method}</p>
            <p>Dirección de envío: {order.shipping_address}</p>
            <p>Total a pagar: {order.total}</p>
            <div className="mt-4">
              <h4 className="font-bold">Productos:</h4>
              {order.products && order.products.length > 0 ? (
                order.products.map((product, index) => (
                  <div key={index} className="border p-2 mt-2 rounded-lg">
                    <p>{product.name}</p>
                    <p>Precio: ${product.price}</p>
                    <p>Cantidad: {product.quantity}</p>
                  </div>
                ))
              ) : (
                <p>No hay productos en esta orden.</p>
              )}
            </div>
            <div>
              <label className="block text-gray-700 font-serif">Estado</label>
              <select
                value={order.status}
                onChange={(e) => handleStatusChange(e, order)}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-400"
              >
                <option value="pendiente">Pendiente</option>
                <option value="transcurso al domicilio">
                  En curso al domicilio
                </option>
                <option value="completado">Completado</option>
              </select>
            </div>
            <button
              onClick={() => handleSaveOrder(order)}
              className="font-serif w-full p-3 mt-4 bg-custom-purple  text-white rounded-lg hover:bg-blue-900 transition-all duration-300"
            >
              Guardar Cambios
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditOrder;
