//Endpoints for components

export const GET_PRODUCTS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/products/select";

export const ADD_PRODUCT_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/products/insert";

export const UPDATE_PRODUCT_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/products/update";

export const DELETE_PRODUCT_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/products/delete";

export const UPLOAD_IMAGE_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/products/upload";

export const POST_ORDERS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/orders/insert";

export const POST_ADMINS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/admins/insert";

export const GET_ADMINS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/admins/select";

export const PUT_ADMINS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/admins/update";

export const DELETE_ADMINS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/admins/delete";

export const GET_LINKS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/links/select";

export const PUT_LINKS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/links/update";

export const GET_ORDERS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/orders/select";

export const PUT_ORDER_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/orders/update";

export const GET_SUPPLIERS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/suppliers/select";

export const PUT_SUPPLIERS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/suppliers/update";

export const DELETE_SUPPLIERS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/suppliers/delete";

export const POST_SUPPLIERS_API =
  "https://pdbidrsat2.execute-api.us-east-1.amazonaws.com/dev/v1/suppliers/insert";
