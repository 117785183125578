import React, { createContext, useState } from "react";

// Creamos el contexto para el carrito
export const CartContext = createContext();

// Proveedor del contexto del carrito
export const CartProvider = ({ children }) => {
  // Estado del carrito
  const [cartItems, setCartItems] = useState([]);
  const maxSpending = 100000; // Límite máximo de gasto

  // Función para añadir un producto al carrito
  const addToCart = (product) => {
    const existingItem = cartItems.find((item) => item.id === product.id);

    if (existingItem) {
      // Si el producto ya está en el carrito, actualizamos la cantidad
      const updatedItems = cartItems.map((item) =>
        item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
      );

      const totalSpending = updatedItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );

      if (totalSpending > maxSpending) {
        alert("No puedes gastar más de $100,000");
        return;
      }

      setCartItems(updatedItems);
    } else {
      // Si el producto no está en el carrito, lo añadimos
      const totalSpending = cartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );

      if (totalSpending + product.price > maxSpending) {
        alert("No puedes gastar más de $100,000");
        return;
      }

      setCartItems((prevItems) => [...prevItems, { ...product, quantity: 1 }]);
    }
  };

  // Función para actualizar la cantidad de un producto en el carrito
  const updateQuantity = (productId, quantity) => {
    if (quantity < 1) return; // No permitir cantidades menores a 1

    const totalSpending = cartItems.reduce(
      (total, item) =>
        item.id === productId
          ? total + item.price * quantity
          : total + item.price * item.quantity,
      0
    );

    // Verificar si el total supera el límite de gasto
    if (totalSpending > maxSpending) {
      alert("No puedes gastar más de $100,000");
      return;
    }

    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      )
    );
  };

  // Función para eliminar un producto del carrito
  const removeFromCart = (productId) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId)
    );
  };

  // Función para calcular el subtotal del carrito
  const calculateSubtotal = () => {
    return cartItems
      .reduce((subtotal, item) => subtotal + item.price * item.quantity, 0)
      .toFixed(2);
  };

  // Función para calcular la cantidad total de unidades en el carrito
  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // Proveedor del contexto del carrito
  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateQuantity,
        removeFromCart,
        calculateSubtotal,
        totalItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
